<template>
  <div class="w-full">
    <div class="flex items-end mb-8 w-full">
      <div>
        <Title title="Campaigns" size="large" class="mb-2" />

        <span class="title__label">
          Showing
          <span class="font-bold">
            {{ getCampaigns != null ? getCampaigns.length : 0 }}
          </span>
          campaigns
        </span>
      </div>
    </div>

    <div v-if="getCampaigns && getCampaigns.length" class="grid md:flex grid-cols-1 gap-8 md:gap-0">
      <CampaignCard
        v-for="(campaign, mobileCampaignIndex) of getCampaigns"
        :key="mobileCampaignIndex"
        class="md:hidden m-auto w-full"
        :title="campaign.title"
        :image-src="
          campaign.thumbnail ||
          'https://storage.googleapis.com/overlay-sources/9daac7f7-5a05-4cfd-9035-5c14b39bab4anetwrk_campaign_fallback.jpg'
        "
        :brand="campaign.brand.title || ''"
        :brand-image="
          brandImages[campaign.brand.id] ||
          'https://storage.googleapis.com/cavea-avatar-service/twitter/netwrkgg/avatar.png'
        "
        :url="`/campaigns/${campaign._id}`"
      />

      <List
        class="hidden md:flex list--campaigns"
        :sort="[
          {
            label: 'Campaign',
            prop: 'date',
            active: true,
          },
          {
            label: 'Views',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Clicks',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Times displayed',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Streamers',
            prop: 'streamerAmount',
            align: 'center',
          },
          /* {
            label: 'Targets',
            prop: 'date',
            align: 'center',
          }, */
          {
            label: '',
            prop: '',
          },
        ]"
      >
        <li v-for="(campaign, desktopCampaignIndex) of getCampaigns" :key="desktopCampaignIndex" class="list__item">
          <div class="list__item-img">
            <img
              v-lazy="
                brandImages[campaign.brand.id] ||
                'https://storage.googleapis.com/cavea-avatar-service/twitter/netwrkgg/avatar.png'
              "
            />
          </div>

          <div class="list__item-content">
            <div class="list__item-col" data-col="1">
              <Button
                class="text-base font-medium button ellipsis"
                :url="`/campaigns/${campaign._id}`"
                :label="campaign.title"
                kind="link"
              />

              <div class="flex items-center w-full">
                <span class="text-xs ellipsis">
                  {{ new Date(campaign.date) | moment("Do MMM YYYY") }}
                </span>

                <Tag
                  v-if="campaign.orgId !== getOrganization._id"
                  label="Non-owned"
                  :random-color="true"
                  class="ml-auto"
                />
              </div>
            </div>

            <div class="items-center list__item-col" data-col="2" title="Total campaign views">
              <Button class="mb-2 text-sm font-medium button ellipsis" kind="link">
                {{ getCampaignViews(campaign._id) }}
              </Button>
            </div>
            <div class="items-center list__item-col" data-col="3" title="Total campaign clicks">
              <Button class="mb-2 text-sm font-medium button ellipsis" kind="link">
                {{ getCampaignLinkClicks(campaign._id) }}
              </Button>
            </div>
            <div class="items-center list__item-col" data-col="4" title="Times displayed">
              <Button class="mb-2 text-sm font-medium button ellipsis" kind="link">
                {{ getCampaignDisplayTimes(campaign._id) }}
              </Button>
            </div>

            <div class="items-center list__item-col" data-col="5" title="Amount of streamers">
              <Button class="mb-2 text-sm font-medium button ellipsis" kind="link">
                {{ campaign.handles.length || 0 }}
              </Button>
            </div>

            <!-- <div class="list__item-col items-center" data-col="6">
              <Button class=" button mb-2 text-sm font-medium ellipsis" title="Campaign targets"
                kind="link"
              >
                0 / {{ campaign.targets.length }}
              </Button>
            </div> -->

            <div class="list__item-col" data-col="0">
              <Dropdown
                :position="['right']"
                :items="[
                  {
                    label: 'View campaign',
                    url: `/campaigns/${campaign._id}`,
                  },
                ]"
                :is-show-more="true"
              />
            </div>
          </div>
        </li>
      </List>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { CampaignCard, Title, Button, Tag } from "cavea-design-system";

export default {
  name: "OrganizationCampaigns",

  metaInfo: {
    title: "Campaigns",
  },

  components: {
    Title,
    Tag,
    Button,
    Dropdown: () => import("@/components/Dropdown"),
    List: () => import("@/modules/List"),
    CampaignCard,
  },

  data() {
    return {
      brandImages: {},
    };
  },

  computed: {
    ...mapGetters(["getCampaigns", "getUserInfo", "getBrands", "getOrganization"]),
  },

  watch: {
    getCampaigns: {
      handler() {
        this.getBrandImg();
      },
    },
  },

  created() {
    if (this.getUserInfo?.type === "streamer") {
      this.$router.push("/");
    }
    this.getBrandImg();
  },

  mounted() {},

  methods: {
    ...mapActions(["fetchCampaigns"]),

    /**
     * @summary get times ad has been displayed
     * @returns {number}
     */
    getCampaignDisplayTimes(id) {
      let displayCount = 0;

      const campaign = this.getCampaigns.find((c) => c._id === id);

      if (campaign?.handles?.length) {
        for (let i = 0; i < campaign.handles.length; ++i) {
          if (campaign?.handles[i]?.statsId?.stats?.length) {
            displayCount += campaign.handles[i].statsId.stats.length;
          }
        }
      }

      return displayCount;
    },

    /**
     * @summary get amount of link clicks
     * @returns {number}
     */
    getCampaignLinkClicks(id) {
      let linkClicks = 0;

      const campaign = this.getCampaigns.find((c) => c._id === id);

      if (campaign?.handles?.length) {
        for (let i = 0; i < campaign.handles.length; ++i) {
          if (campaign?.handles[i]?.redirectorId?.stats?.length) {
            linkClicks += campaign.handles[i].redirectorId.stats.length;
          }
        }
      }

      return linkClicks;
    },

    /**
     * @summary get campaign total ad views
     * @returns {number} total views
     */
    getCampaignViews(id) {
      let totalViews = 0;

      const campaign = this.getCampaigns.find((c) => c._id === id);

      if (campaign?.handles?.length) {
        for (let i = 0; i < campaign.handles.length; ++i) {
          if (campaign?.handles[i]?.statsId?.stats?.length)
            for (let j = 0; j < campaign.handles[i].statsId.stats.length; ++j) {
              if (campaign?.handles[i]?.statsId?.stats[j]?.views) {
                totalViews += campaign.handles[i].statsId.stats[j].views;
              }
            }
        }
      }

      return totalViews;
    },

    /**
     * @summary get campaign link ctr
     * @returns {number} ctr
     */
    getCampaignLinkCtr(id) {
      let ctr = 0;
      const views = this.getCampaignViews(id);
      const clicks = this.getCampaignLinkClicks(id);

      if (views && clicks) {
        ctr = ((clicks / views) * 100).toFixed(2);
      }
      return ctr;
    },

    /**
     * @summary get  campaign average ad views
     * @returns {number} avgViews
     */
    getCampaignAverageViews(id) {
      let avg = 0;
      const views = this.getCampaignViews(id);
      const displayTimes = this.getCampaignDisplayTimes(id);

      if (views && displayTimes) {
        avg = (views / displayTimes).toFixed(2);
      }

      return avg;
    },

    async getBrandImg() {
      if (this.getCampaigns) {
        for (const campaign of this.getCampaigns) {
          if (campaign?.brand?.id && this.brandImages[campaign?.brand?.id] === undefined) {
            const brandIndex = this.getBrands?.findIndex((b) => b?._id === campaign?.brand?.id);

            if (brandIndex !== -1) {
              this.brandImages[campaign?.brand?.id] = this.getBrands[brandIndex]?.logo || null;
            } else {
              const HOST = process.env.VUE_APP_API_URL;
              const URL = `${HOST}/brand-logo/${campaign?.brand?.id}`;

              await axios
                .get(URL)
                .then((res) => {
                  this.brandImages[campaign?.brand?.id] = res?.data?.image;
                })
                .catch((error) => {
                  console.error("fetchNonOwnedBrandImage error", error);
                  return null;
                });
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.list--campaigns {
  // Title
  [data-col="1"] {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  [data-col="2"],
  [data-col="3"],
  [data-col="4"],
  [data-col="5"],
  [data-col="6"] {
    width: calc(22.5% - 1.5rem);
    margin-right: 1.5rem;
    align-items: center;
  }

  // Dropdown
  [data-col="0"] {
    width: 1.25rem;
    margin: 0 1.5rem 0 1.5rem;
  }
}
</style>
