var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex items-end mb-8 w-full"},[_c('div',[_c('Title',{staticClass:"mb-2",attrs:{"title":"Campaigns","size":"large"}}),_c('span',{staticClass:"title__label"},[_vm._v(" Showing "),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.getCampaigns != null ? _vm.getCampaigns.length : 0)+" ")]),_vm._v(" campaigns ")])],1)]),(_vm.getCampaigns && _vm.getCampaigns.length)?_c('div',{staticClass:"grid md:flex grid-cols-1 gap-8 md:gap-0"},[_vm._l((_vm.getCampaigns),function(campaign,mobileCampaignIndex){return _c('CampaignCard',{key:mobileCampaignIndex,staticClass:"md:hidden m-auto w-full",attrs:{"title":campaign.title,"image-src":campaign.thumbnail ||
        'https://storage.googleapis.com/overlay-sources/9daac7f7-5a05-4cfd-9035-5c14b39bab4anetwrk_campaign_fallback.jpg',"brand":campaign.brand.title || '',"brand-image":_vm.brandImages[campaign.brand.id] ||
        'https://storage.googleapis.com/cavea-avatar-service/twitter/netwrkgg/avatar.png',"url":("/campaigns/" + (campaign._id))}})}),_c('List',{staticClass:"hidden md:flex list--campaigns",attrs:{"sort":[
        {
          label: 'Campaign',
          prop: 'date',
          active: true,
        },
        {
          label: 'Views',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Clicks',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Times displayed',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Streamers',
          prop: 'streamerAmount',
          align: 'center',
        },
        /* {
          label: 'Targets',
          prop: 'date',
          align: 'center',
        }, */
        {
          label: '',
          prop: '',
        } ]}},_vm._l((_vm.getCampaigns),function(campaign,desktopCampaignIndex){return _c('li',{key:desktopCampaignIndex,staticClass:"list__item"},[_c('div',{staticClass:"list__item-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              _vm.brandImages[campaign.brand.id] ||
              'https://storage.googleapis.com/cavea-avatar-service/twitter/netwrkgg/avatar.png'
            ),expression:"\n              brandImages[campaign.brand.id] ||\n              'https://storage.googleapis.com/cavea-avatar-service/twitter/netwrkgg/avatar.png'\n            "}]})]),_c('div',{staticClass:"list__item-content"},[_c('div',{staticClass:"list__item-col",attrs:{"data-col":"1"}},[_c('Button',{staticClass:"text-base font-medium button ellipsis",attrs:{"url":("/campaigns/" + (campaign._id)),"label":campaign.title,"kind":"link"}}),_c('div',{staticClass:"flex items-center w-full"},[_c('span',{staticClass:"text-xs ellipsis"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(campaign.date),"Do MMM YYYY"))+" ")]),(campaign.orgId !== _vm.getOrganization._id)?_c('Tag',{staticClass:"ml-auto",attrs:{"label":"Non-owned","random-color":true}}):_vm._e()],1)],1),_c('div',{staticClass:"items-center list__item-col",attrs:{"data-col":"2","title":"Total campaign views"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium button ellipsis",attrs:{"kind":"link"}},[_vm._v(" "+_vm._s(_vm.getCampaignViews(campaign._id))+" ")])],1),_c('div',{staticClass:"items-center list__item-col",attrs:{"data-col":"3","title":"Total campaign clicks"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium button ellipsis",attrs:{"kind":"link"}},[_vm._v(" "+_vm._s(_vm.getCampaignLinkClicks(campaign._id))+" ")])],1),_c('div',{staticClass:"items-center list__item-col",attrs:{"data-col":"4","title":"Times displayed"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium button ellipsis",attrs:{"kind":"link"}},[_vm._v(" "+_vm._s(_vm.getCampaignDisplayTimes(campaign._id))+" ")])],1),_c('div',{staticClass:"items-center list__item-col",attrs:{"data-col":"5","title":"Amount of streamers"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium button ellipsis",attrs:{"kind":"link"}},[_vm._v(" "+_vm._s(campaign.handles.length || 0)+" ")])],1),_c('div',{staticClass:"list__item-col",attrs:{"data-col":"0"}},[_c('Dropdown',{attrs:{"position":['right'],"items":[
                {
                  label: 'View campaign',
                  url: ("/campaigns/" + (campaign._id)),
                } ],"is-show-more":true}})],1)])])}),0)],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }